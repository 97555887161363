import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import AppRoutes from "./components/Utils/Routes";

// const IconRow = ({ icons }) => (
//   <div className="icon-row">
//     {icons.map((icon, index) => (
//       <img key={index} src={icon} alt={`Icon ${index + 1}`} className="icon" />
//     ))}
//   </div>
// );

function App() {
  return (
    <Router>
    <div className="App" style={{ overflowX: "hidden" }}>
      <Navbar />
      <AppRoutes/>
      <Footer />
    </div>
</Router>
  );
}

export default App;
