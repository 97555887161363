import {Route, Routes} from "react-router-dom";
import Home from "../../Routes/Home/Home";
import Services from "../../Routes/Services/Services";
import Technologies from "../../Routes/Technologies/Technologies";
import Contact from "../../Routes/Contact/Contact";
import AboutUs from "../../Routes/About/AboutUs";
import ScrollToTop from "./ScrollTop";

export const routes = {
    home: '/home',
    services: '/services',
    technologies: '/technologies',
    contact: '/contact',
    about: '/about',
    default: '/'
}

const AppRoutes = () => {
    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route exact path={routes.default} element={<Home />} />
                <Route path={routes.home} element={<Home />} />
                <Route path={routes.services} element={<Services />} />
                <Route path={routes.technologies} element={<Technologies />} />
                <Route path={routes.contact} element={<Contact />} />
                <Route path={routes.about} element={<AboutUs />} />
            </Routes>
        </>
    )
}

export default AppRoutes;