export const images = {
  Benefits: require("./images/Benefits.png"),
  ApproachCycle: require("./images/Approach cycle.png"),
  Partners: require("./images/Partners.png"),
  Elementor: require("./images/Elementor.png"),
  Facebook: require("./images/Facebook.png"),
  Instagram: require("./images/Instagram.png"),
  Linkedin: require("./images/Linkedin.png"),
  Email: require("./images/Email.png"),
  WhatsApp: require("./images/WhatsApp.png"),
  LogoTaxonomy: require("./images/LogoTaxonomyTech.png"),
  Molecule3d: require("./images/Molecule3d.png"),
  Form2d: require("./images/form2d.png"),
  IAtech: require("./images/IA-Tech.png"),
  Ourvision: require("./images/Ourvision.png"),
  Linkedins: require("./images/Linkedins.png"),
  Github: require("./images/Github.png"),
  Matias: require("./images/Matias.png"),
  Iracy: require("./images/Iracy.png"),
  Nico: require("./images/Nico.png"),
  Myrella: require("./images/Mirella.png"),
  Molecularmodel: require("./images/molecularmode_services.png"),
  Biologicaldata: require("./images/biologicaldata_services.png"),
  ProcessAutomation: require("./images/processauto_services.png"),
  Moleculartaxa: require("./images/moleculartax_services.png"),

};

// ];
