import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { images } from "../../asset/assets";
import "./Home.css";

const Home = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.defer = true;
    script.setAttribute("data-use-service-core", true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="home">
      <div className="background">
        <video autoPlay loop muted playsInline>
          <source src="/backgroundMainIndex.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="content">
          <h1>MOLECULAR MODELING AND SIMULATION</h1>
          <h2>
            At Taxonomy Tech Solutions, we utilize synthetic biology and
            computational
            <br />
            modeling to measure, predict, and design molecular interactions.
            Using
            <br />
            artificial intelligence and machine learning, we create innovative
            solutions
            <br />
            that transform the future of healthcare.
          </h2>
          <a
            href="https://wa.me/+5541987261307"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-primary">Schedule a Call</button>
          </a>
        </div>
      </div>

      <div className="info-section">
        <div className="content-wrapper">
          <div className="text-content">
            <h2>Our approach</h2>
            <p>
              Our methodology is based on the integration of synthetic biology,
              computational modeling, and advanced data analysis techniques. Our
              process begins with the collection and analysis of data according
              to the client's demands.
            </p>
            <p>
              We utilize advanced software to develop precise
              three-dimensional models of molecules, allowing for detailed
              visualization and understanding of their structures and behaviors.
            </p>
            <p>
              We apply artificial intelligence and machine learning techniques
              to analyze large volumes of data and predict molecular
              interactions, identifying complex patterns and developing
              personalized solutions for each health challenge.
            </p>
            <p>
              Additionally, our entire infrastructure operates on cloud
              computing provided by Amazon Web Services (AWS), ensuring that our
              processes are carried out efficiently, quickly, scalably, and
              securely.
            </p>
          </div>
          <div className="image-content">
            <img src={images.ApproachCycle} alt="Approach Cycle" />
          </div>
        </div>
        <div className="benefits-section">
          <h2>Benefits of approach</h2>
          <div className="benefits-image">
            <img src={images.Benefits} alt="Benefits of Our Approach" />
          </div>
          <p>
            Our approach provides precision, reliability, and personalized
            biotechnology solutions, with scalability and security
            <br />
            ensured by AWS, along with rapid analysis of large data volumes
            using AI and machine learning.
          </p>
        </div>
      </div>

      <div className="partners-section">
        <div className="content-wrapper">
          <div className="text-content">
            <h2>Our partners</h2>
            <p>
              We believe that strategic collaborations are essential to drive
              innovation and achieve significant advancements in human health.
              We are committed to forming partnerships with leading companies
              and institutions that share our vision of transforming the future
              of biotechnology.
            </p>
            <p>
              We invite companies, research institutions, and other stakeholders
              to join us on this transformative journey. Together, we can
              explore new frontiers, develop innovative therapies, and tackle
              the most complex challenges, creating a lasting impact on the
              quality of life on a global scale.
            </p>
            <Link to="/technologies">
              Learn more about our technology
            </Link>
          </div>
          <div className="image-content">
            <img src={images.Partners} alt="Partners" />
          </div>
        </div>
      </div>

      <div className="innovation-events-section">
        <h2>Participation in innovation events</h2>
        <div className="innovation-events-background">
          <div className="linkedin-widget">
            <div
              className="elfsight-app-1c1b63ce-d539-4e22-ba46-5a2c3c2c1b06"
              data-elfsight-app-lazy
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;