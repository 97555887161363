import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logoImageTaxonomy from "../../asset/images/LogoWebsiteTaxonomyTech.png";
import sandwichIcon from "../../asset/icons/sandwich-icon.svg";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const menuItems = document.querySelectorAll('.menu-item');
    menuItems.forEach((item, index) => {
      item.style.setProperty('--animation-order', index);
    });
  }, [isOpen]);

  const handleMenuItemClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="top-line"></div>
      <nav className="navbar">
        <div className="menu-section">
          <ul className={`menu-left ${isOpen ? "active" : ""}`}>
            <li className="menu-item">
              <Link to="/technologies" className="menu-link" onClick={handleMenuItemClick}>
                Technologies
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/about" className="menu-link" onClick={handleMenuItemClick}>
                About Us
              </Link>
            </li>
          </ul>
            
          <div className="logo">
            <Link to="/home" className="logo-text" onClick={handleMenuItemClick}>
              <img src={logoImageTaxonomy} alt="LogoTaxonomy" className="logo-image" />
            </Link>
          </div>
          <ul className={`menu-right ${isOpen ? "active" : ""}`}>
            <li className="menu-item">
              <Link to="/services" className="menu-link" onClick={handleMenuItemClick}>
                Services
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/contact" className="menu-link" onClick={handleMenuItemClick}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="sandwich" onClick={toggleMenu}>
          <img src={sandwichIcon} alt="Menu" />
        </div>
      </nav>
    </>
  );
}

export default Navbar;