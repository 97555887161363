import React from 'react';
import { images } from "../../asset/assets";
import { Link } from "react-router-dom";
import "./Technologies.css"

const Technologies = () => (
  <div className="technology-section">
    <div className='tech-body'>
      <div className="about-our-technology">
        <h2>About the Technology We Use</h2>
        <p>Our approach integrates molecular modeling, genomic and proteomic analysis, and cloud computing.</p>
      </div>
      <div className="our-technology-background">
        <img src={images.Molecule3d} alt="Molecule" className="molecule-image" />
      </div>
    </div>
    <div className='tech-element'>
      <img src={images.Elementor} alt="tech-element" />
    </div>

    {/* Divisoria de metodologias */}
    <div className="new-technology-section">
      <div className="technology-columns">
        <div className="technology-column">
          <h3>Molecular Modeling and Simulation</h3>
          <p>We utilize advanced computational modeling techniques such as molecular dynamics (MD) and molecular docking to predict biomolecular interactions and develop new therapeutic compounds.</p>
          <h3>Molecular Taxonomy</h3>
          <p>We optimize the processing of large-scale biological data. Our approach automates repetitive and complex tasks using automated pipelines like Snakemake and Galaxy for genomic and proteomic data analysis, ensuring speed and accuracy.</p>
        </div>
        <div className="technology-column">
          <img src={images.Form2d} alt="Form 2D" className="form-image" />
        </div>
        <div className="technology-column">
          <h3>Genomic Analysis</h3>
          <p>We use next-generation DNA sequencing (NGS) platforms for gene expression analysis (RNA-Seq), genome assembly, and variant identification.</p>
          <h3>Automation of Processing</h3>
          <p>We offer scalable and secure cloud computing solutions with Amazon Web Services (AWS). Our services include using SageMaker for machine learning applications, enabling the processing of large volumes of biological data securely and efficiently.</p>
        </div>
      </div>
    </div>

    {/* Nova seção de imagem */}
    <div className="image-section">
      <img src={images.IAtech} alt="IA Tech" className="full-width-image" />
    </div>

    <div className="future-vision-section">
      <img src={images.Ourvision} alt="Ourvision" className="future-vision-image" />
      <div className="future-vision-text">
        <h2>Our Vision for the Future of Technology</h2>
        <p>We are investing in research and the development of next-generation tools and platforms for advancements in synthetic biology.</p>
        <Link to="/contact" className="schedule-call-button">Schedule a call</Link>
      </div>
    </div>
    <hr className="section-divider" />
  </div>
);

export default Technologies;
