// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-section {
  background-color: #071424;
  color: #ffffff;
  text-align: center;
  margin-top: -20px;
}

.services-section h2 {
  font-family: "Outfit";
  font-size: 1.7em;
  padding: 40px 0 0 0;
}

.service-item {
  display: flex;
  align-items: center;
  margin: 30px;
}

.service-icon {
  width: 120px;
}

.service-description {
  text-align: justify;
}

.service-description h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.service-description p {
  font-size: 1.2em;
}

.services-info {
  font-size: 1.2em;
  margin-top: 100px;
}

.schedule-call-button {
  background-color: #B1D07E;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

.schedule-call-button:hover {
  background-color: #A0C06E;
}

.tech-element {
  background-size: auto;
  text-align: justify;
}
`, "",{"version":3,"sources":["webpack://./src/Routes/Services/Services.css"],"names":[],"mappings":"AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');\n\n.services-section {\n  background-color: #071424;\n  color: #ffffff;\n  text-align: center;\n  margin-top: -20px;\n}\n\n.services-section h2 {\n  font-family: \"Outfit\";\n  font-size: 1.7em;\n  padding: 40px 0 0 0;\n}\n\n.service-item {\n  display: flex;\n  align-items: center;\n  margin: 30px;\n}\n\n.service-icon {\n  width: 120px;\n}\n\n.service-description {\n  text-align: justify;\n}\n\n.service-description h3 {\n  font-size: 1.2em;\n  margin-bottom: 10px;\n}\n\n.service-description p {\n  font-size: 1.2em;\n}\n\n.services-info {\n  font-size: 1.2em;\n  margin-top: 100px;\n}\n\n.schedule-call-button {\n  background-color: #B1D07E;\n  color: #ffffff;\n  border: none;\n  border-radius: 20px;\n  padding: 10px 20px;\n  font-size: 1em;\n  cursor: pointer;\n}\n\n.schedule-call-button:hover {\n  background-color: #A0C06E;\n}\n\n.tech-element {\n  background-size: auto;\n  text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
