import React, { useEffect } from 'react';
import { images } from "../../asset/assets";
import "./Contact.css";

const Contact = () => {
  useEffect(() => {
    const loadCalendlyWidget = async () => {
      const script = document.createElement("script");
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        console.log("Calendly widget script loaded successfully.");
      };

      script.onerror = () => {
        console.error("Error loading Calendly widget script.");
      };
    };

    loadCalendlyWidget();
  }, []);

  return (
    <>
      <div className="contact-section">
        <h2>Contact</h2>
        <p>For general inquiries, use the form below or email <a href="mailto:info@taxonomytech.org">info@taxonomytech.org</a></p>
        <div className="calendly-wrapper">
          <div className="calendly-inline-widget" data-url="https://calendly.com/taxonomy-tech-solutions/30-minute-meeting-clone"></div>
        </div>
        <div className='tech-element'>
          <img src={images.Elementor} alt="tech-element" className="full-width-image" />
        </div>
      </div>
    </>
  );
};

export default Contact;