import React from 'react';
import { images } from "../../asset/assets";
import "./AboutUs.css";

const AboutUs = () => (
  <div className="about-us-section">
    <div className="about-us-content">
      <h2>About Us</h2>
      <p>At Taxonomy Tech Solutions, our mission is to transform global health through innovation in biotechnology. We combine synthetic biology, computational modeling, and advanced data analysis to create personalized solutions that address the most complex challenges in healthcare.</p>
      <h2>Our Team</h2>
      <p>Our team is composed of experts in biotechnology, bioinformatics, artificial intelligence, and big data analysis. With extensive experience and a passion for innovation, we are committed to delivering high-quality, impactful solutions.</p>
    </div>
    <div className="team-section">
      <h2>Our Team</h2>
      <div className="team-grid">
        <div className="team-member">
          <img src={images.Matias} alt="Matias Duarte" className="team-photo" />
          <div className="team-info">
            <strong>Matias Duarte</strong>
            <p className="qualification">Co-Founder</p>
            <p>DevOps Architect</p>
            <div className="team-links">
              <a href="https://www.linkedin.com/in/fernandomatiasduarte/" target="_blank" rel="noopener noreferrer">
                <img src={images.Linkedins} alt="LinkedIn" />
              </a>
              <a href="https://github.com/MattyDroidX" target="_blank" rel="noopener noreferrer">
                <img src={images.Github} alt="GitHub" />
              </a>
            </div>
          </div>
        </div>

        <div className="team-member">
          <img src={images.Iracy} alt="Iracy Soares" className="team-photo" />
          <div className="team-info">
            <strong>Iracy Soares</strong>
            <p className="qualification">Co-Founder</p>
            <p>Biotechnologist</p>
            <div className="team-links">
              <a href="https://www.linkedin.com/in/iracymaiany/" target="_blank" rel="noopener noreferrer">
                <img src={images.Linkedins} alt="LinkedIn" />
              </a>
              <a href="https://github.com/IracyMaia" target="_blank" rel="noopener noreferrer">
                <img src={images.Github} alt="GitHub" />
              </a>
            </div>
          </div>
        </div>

        <div className="team-member">
          <img src={images.Nico} alt="Nicolas Dasanbiagio" className="team-photo" />
          <div className="team-info">
            <strong>Nicolas Dasanbiagio</strong>
            <p className="qualification">Senior</p>
            <p>Python Engineer</p>
            <div className="team-links">
              <a href="https://www.linkedin.com/in/nicolas-dasanbiagio/" target="_blank" rel="noopener noreferrer">
                <img src={images.Linkedins} alt="LinkedIn" />
              </a>
              <a href="https://github.com/ndasanbiagio" target="_blank" rel="noopener noreferrer">
                <img src={images.Github} alt="GitHub" />
              </a>
            </div>
          </div>
        </div>

        <div className="team-member">
          <img src={images.Myrella} alt="Myrella Araujo" className="team-photo" />
          <div className="team-info">
            <strong>Myrella Araujo</strong>
            <p className="qualification">Intern</p>
            <p>Biologist</p>
            <div className="team-links">
              <a href="https://www.linkedin.com/in/myrella-araujo-b82a98300/" target="_blank" rel="noopener noreferrer">
                <img src={images.Linkedins} alt="LinkedIn" />
              </a>
              <a href="https://github.com/myrella-araujo-b82a98300" target="_blank" rel="noopener noreferrer">
                <img src={images.Github} alt="GitHub" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="vacancy-info">
        Are you interested? <br />
        Fill out the form for the reserve vacancy bank <a href="https://www.linkedin.com/company/taxonomytech/jobs/" target="_blank" rel="noopener noreferrer">here</a>
      </p>
    </div>
    <div className='tech-element'>
      <img src={images.Elementor} alt="tech-element" />
    </div>
  </div>
);

export default AboutUs;
