import React from "react";
import "./Footer.css";
import { images } from "../../asset/assets";

const Footer = () => (
  <footer className="footer">
    <ul className="footer-content">
      <li className="footer-logo">
        <img src={images.LogoTaxonomy} alt="TaxonomyTech Logo" />
      </li>
        <li className="footer-column">
          <h3>Contact</h3>
          <p>Email: <a href="mailto:info@taxonomytech.org">info@taxonomytech.org</a></p>
          <p>Fone: <a href="tel:+5541987261307">+55 41 98726-1307</a></p>
        </li>
        <li className="footer-column">
          <h3>Address</h3>
          <p>Curitiba, Brazil</p>
        </li>
        <li className="footer-column">
          <h3>Social Media</h3>
          <a href="https://www.linkedin.com/company/taxonomytech/" target="_blank" rel="noreferrer">
            <img src={images.Linkedin} alt="LinkedIn" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61558310653131" target="_blank" rel="noreferrer">
            <img src={images.Facebook} alt="Facebook" />

          </a>
          <a href="https://www.instagram.com/taxonomytech/" target="_blank" rel="noreferrer">
            <img src={images.Instagram} alt="Instagram" />
          </a>
        </li>
    </ul>
    <div className="footer-bottom">
      <p>Copyright © 2024 TaxonomyTech Solutions - All Rights Reserved | <a href="/terms">Terms of use</a></p>
    </div>
  </footer>
);

export default Footer;
