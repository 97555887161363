import React from 'react';
import { images } from "../../asset/assets";
import "./Services.css";

const Services = () => (
  <>
    <div className="services-section">
      <h2>Services</h2>
      <div className="service-item">
        <img src={images.Molecularmodel} alt="Molecular Modeling" className="service-icon" />
        <div className="service-description">
          <h3>Molecular Modeling and Simulation</h3>
          <p>We use advanced molecular modeling and simulation techniques to predict biomolecular interactions and develop new therapeutic compounds. Our cloud computing infrastructure allows for the efficient and secure execution of complex simulations.</p>
        </div>
      </div>

      <div className="service-item">
        <img src={images.Biologicaldata} alt="Biological Data Analysis" className="service-icon" />
        <div className="service-description">
          <h3>Biological Data Analysis</h3>
          <p>We offer biological data analysis services, utilizing advanced bioinformatics and big data tools. Our services include the analysis of genomic sequences, gene expression, proteomics, and more.</p>
        </div>
      </div>

      <div className="service-item">
        <img src={images.ProcessAutomation} alt="Process Automation" className="service-icon" />
        <div className="service-description">
          <h3>Process Automation</h3>
          <p>We optimize and automate complex and repetitive tasks in biotechnology. Our solutions increase efficiency, reduce research time, and ensure precise and reliable data processing.</p>
        </div>
      </div>

      <div className="service-item">
        <img src={images.Moleculartaxa} alt="Molecular Taxonomic Analysis" className="service-icon" />
        <div className="service-description">
          <h3>Molecular Taxonomic Analysis</h3>
          <p>We provide molecular taxonomic analysis services using bioinformatics tools to process and analyze molecular data from biological species.</p>
        </div>
      </div>

      <p className="services-info">
        Ready to learn more about how our services can benefit your project? 
        <br />
        Contact us today to discuss your needs and how we can help.
        <br /> 
      </p>
      <button href="https://www.taxonomytech.org/contact" className="schedule-call-button">Schedule a call</button>
    

    <div className='tech-element'>
      <img src={images.Elementor} alt="tech-element" />
    </div>
    </div>
  </>
);

export default Services;
