// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-section {
  background-color: #071424;
  font-family: "Outfit";
  color: #ffffff;
  text-align: center;
  padding: 50px 0 0 0;
}

.contact-section h2 {
  font-size: 1.7em;
  margin-bottom: 10px;
}

.contact-section p {
  font-size: 1.1em;
}

.contact-section a {
  color: #ffffff;
  text-decoration: underline;
}

.calendar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendly-inline-widget{
    min-width: 200px;
    height: 900px;
}

.tech-element {
  text-align: justify;
}

.full-width-image {
  width: 100%;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/Routes/Contact/Contact.css"],"names":[],"mappings":"AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');\n\n.contact-section {\n  background-color: #071424;\n  font-family: \"Outfit\";\n  color: #ffffff;\n  text-align: center;\n  padding: 50px 0 0 0;\n}\n\n.contact-section h2 {\n  font-size: 1.7em;\n  margin-bottom: 10px;\n}\n\n.contact-section p {\n  font-size: 1.1em;\n}\n\n.contact-section a {\n  color: #ffffff;\n  text-decoration: underline;\n}\n\n.calendar-wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.calendly-inline-widget{\n    min-width: 200px;\n    height: 900px;\n}\n\n.tech-element {\n  text-align: justify;\n}\n\n.full-width-image {\n  width: 100%;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
